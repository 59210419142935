import React, { lazy } from "react";

const Login = lazy(() => import("../Components/Login/Login"));
const Dashboard = lazy(() => import("../Components/Dashboard/Dashboard"));
const AdminDashBoard = lazy(() =>
  import("../Components/adminDashboard/adminDashboard")
);
const MerchantDetails = lazy(() =>
  import("../Components/Merchants/MerchantDetails")
);
const AccountNumbers = lazy(() =>
  import("../Components/AccountNumbers/AccountNumbers")
);
const BbpsReports = lazy(() => import("../Components/BbpsReports/BbpsReports"));
const Maintenance = lazy(() => import("../Components/Maintenance/Maintenance"));
const OperatorsUpdate = lazy(() =>
  import("../Components/Maintenance/OperatorsUpdate/OperatorsUpdate")
);
const OperatorsParam = lazy(() =>
  import("../Components/Maintenance/OperatorsParam/OperatorsParam")
);
const SchemeCreation = lazy(() =>
  import("../Components/SchemeCreation/SchemeCreation")
);
const ViewServices = lazy(() =>
  import("../Components/ServiceCreation/ViewServices")
);
const ServiceMaintenance = lazy(() =>
  import("../Components/Maintenance/ServiceMaintenance")
);
const Operatorssevicemaintainence = lazy(() =>
  import("../Components/Maintenance/Operatorssevicemaintainence")
);
const CommissionCreation = lazy(() =>
  import("../Components/CommissionsCreation/CommissionCreation")
);
const Transactionapi = lazy(() =>
  import("../Components/Transactionapi/Transactionapi")
);
const Transitionservices = lazy(() =>
  import("../Components/Transactionapi/Transitionservices")
);
const Smsapi = lazy(() => import("../Components/Smsapi/Smsapi"));
const APIForwarding = lazy(() =>
  import("../Components/APIForwarding/APIForwarding")
);
const Smsapiservices = lazy(() =>
  import("../Components/Smsapi/Smsapiservices")
);
const APIForwardingscheme = lazy(() =>
  import("../Components/APIForwardingscheme/APIForwardingscheme")
);
const APICommission = lazy(() =>
  import("../Components/APICommission/APICommission")
);
const SMSTemplate = lazy(() => import("../Components/SMSTemplate/SMSTemplate"));
const Bank = lazy(() => import("../Components/Bank/Bank"));
const BankIfsc = lazy(() => import("../Components/BankIfsc/BankIfsc"));
const DigitalWalletDashBoard = lazy(() =>
  import("../Components/DigitalWallet/DigitalDashBoard")
);
const Createuser = lazy(() => import("../Components/CreateUser/CreateUser"));
const EditDetails = lazy(() =>
  import("../Components/EdituserDetails/EditDetails")
);
const BBPSServices = lazy(() =>
  import("../Components/BBPSServices/BBPSServicesUpload")
);

const TransactionReports = lazy(() =>
  import("../Components/TransactionReports/TransactionReports")
);

const LiveTransactionReport = lazy(() =>
  import("../Components/LiveTransactionReport/LiveTransactionReport")
);

const LiveTransactionServices = lazy(() =>
  import("../Components/LiveTransactionReport/LiveTransactionServices")
);

const PendingTransactions = lazy(() =>
  import("../Components/PendingTransactions/PendingTransactions")
);

const PendingTransactionservices = lazy(() =>
  import("../Components/PendingTransactions/PendingTransactionservices")
);

const Model = lazy(() =>
  import("../Components/PendingTransactions/Model/Model")
);

const APISaleReport = lazy(() =>
  import("../Components/APISaleReport/APISaleReport")
);

const APISaleReportservices = lazy(() =>
  import("../Components/APISaleReport/APISaleReportservices")
);

const RefundReports = lazy(() =>
  import("../Components/RefundReports/RefundReports")
);

const UpdateTransactions = lazy(() =>
  import("../Components/UpdateTransactions/UpdateTransactions")
);

const UpdateTransactionsservices = lazy(() =>
  import("../Components/UpdateTransactions/UpdateTranactionsservices")
);

const SMSReport = lazy(() => import("../Components/SMSReport/SMSReport"));

const SMSReportservices = lazy(() =>
  import("../Components/SMSReport/SMSReportservices")
);

const Summary = lazy(() => import("../Components/Summary/Summary"));
const UserSummary = lazy(() => import("../Pages/UserSummary/UserSummary"));
const ReferSummary = lazy(() => import("../Pages/ReferSummary/ReferSummary"));
const GSTTDS = lazy(() => import("../Pages/GSTTDS/GSTTDS"));
const UserUsage = lazy(() => import("../Pages/UserUsage/UserUsage"));
const APIReconciliation = lazy(() =>
  import("../Pages/Reconciliation/APIReconciliation/APIReconciliation")
);
const APIReconciliationservices = lazy(() =>
  import("../Pages/Reconciliation/APIReconciliation/APIReconciliationservices")
);
const OperatorReconciliation = lazy(() =>
  import(
    "../Pages/Reconciliation/OperatorReconciliation/OperatorReconciliation"
  )
);

const OperatorReconciliationservices = lazy(() =>
  import(
    "../Pages/Reconciliation/OperatorReconciliation/OperatorReconciliationservices"
  )
);

const OperatorReconciliationcustom = lazy(() =>
  import(
    "../Pages/Reconciliation/OperatorReconciliationcustom/OperatorReconciliationcustom"
  )
);

const Operatorcustomservices = lazy(() =>
  import(
    "../Pages/Reconciliation/OperatorReconciliationcustom/Operatorcustomservices"
  )
);

const Extranodebit = lazy(() =>
  import("../Pages/Reconciliation/Extranodebit/Extranodebit")
);

const MissingBalance = lazy(() =>
  import("../Pages/Reconciliation/MissingBalance/MissingBalance")
);

const MissingBalanceservices = lazy(() =>
  import("../Pages/Reconciliation/MissingBalance/MissingBalanceservices")
);

const Transactioncountserwise = lazy(() =>
  import(
    "../Pages/Reconciliation/Transactioncountserwise/Transactioncountserwise"
  )
);

const Transactioncountservice = lazy(() =>
  import(
    "../Pages/Reconciliation/Transactioncountserwise/Transactioncountservice"
  )
);

const Operatorcommwise = lazy(() =>
  import("../Pages/Reconciliation/Operatorcommwise/Operatorcommwise")
);

const Operatorcommwiseservice = lazy(() =>
  import("../Pages/Reconciliation/Operatorcommwise/Operatorcommwiseservice")
);

const Txnprocesscount = lazy(() =>
  import("../Pages/Reconciliation/Txnprocesscount/Txnprocesscount")
);

const Txnprocesscountservice = lazy(() =>
  import("../Pages/Reconciliation/Txnprocesscount/Txnprocesscountservice")
);

const ReconciliationReport = lazy(() =>
  import("../Pages/Reconciliation/ReconciliationReport/ReconciliationReport")
);

const ReconciliationReportservice = lazy(() =>
  import(
    "../Pages/Reconciliation/ReconciliationReport/ReconciliationReportservice"
  )
);

const DownlineSummary = lazy(() =>
  import("../Pages/DownlineSummary/DownlineSummary")
);

const DownlineSummarytable = lazy(() =>
  import("../Pages/DownlineSummary/DownlineSummarytable")
);
const PaymentStatus = lazy(() =>
  import("../Pages/PaymentStatus/PaymentStatus")
);
const PaymentStatustable = lazy(() =>
  import("../Pages/PaymentStatus/PaymentStatustable")
);
const DuplicateTransactions = lazy(() =>
  import("../Pages/DuplicateTransactions/DuplicateTransactions")
);
const DuplicateTransactionstable = lazy(() =>
  import("../Pages/DuplicateTransactions/DuplicateTransactionstable")
);
const RequestLog = lazy(() =>
  import("../Pages/RequestLog/RequestLog")
);
const RequestLogtable = lazy(() =>
  import("../Pages/RequestLog/RequestLogtable")
);
const ResponseLog = lazy(() =>
  import("../Pages/ResponseLog/ResponseLog")
);
const ResponseLogtable = lazy(() =>
  import("../Pages/ResponseLog/ResponseLogtable")
);
const ActivityLog = lazy(() =>
  import("../Pages/ActivityLog/ActivityLog")
);
const ActivityLogtable = lazy(() =>
  import("../Pages/ActivityLog/ActivityLogtable")
);
const DuplicateLog = lazy(() =>
  import("../Pages/DuplicateDepositeLog/DuplicateLog")
);
const DuplicateLogtable = lazy(() =>
  import("../Pages/DuplicateDepositeLog/DuplicateLogtable")
);
// Define RoutesConfig as an array of route objects
const RoutesConfig = [
  { path: "/", element: <Login /> },
  { path: "/Dashboard", element: <Dashboard /> },
  { path: "/adminDashboard", element: <AdminDashBoard /> },
  { path: "/MerchantDetails", element: <MerchantDetails /> },
  { path: "/AccountNumbers", element: <AccountNumbers /> },
  { path: "/BbpsReports", element: <BbpsReports /> },
  { path: "/Maintenance", element: <Maintenance /> },
  { path: "/OperatorsUpdate", element: <OperatorsUpdate /> },
  { path: "/OperatorsParam", element: <OperatorsParam /> },
  { path: "/SchemeCreation", element: <SchemeCreation /> },
  { path: "/ViewServices", element: <ViewServices /> },
  { path: "/ServiceMaintenance", element: <ServiceMaintenance /> },
  { path: "/CommissionCreation", element: <CommissionCreation /> },
  { path: "/Transactionapi", element: <Transactionapi /> },
  { path: "/Transitionservices", element: <Transitionservices /> },
  { path: "/Smsapi", element: <Smsapi /> },
  { path: "/APIForwarding", element: <APIForwarding /> },
  { path: "/APIForwardingscheme", element: <APIForwardingscheme /> },
  { path: "/APICommission", element: <APICommission /> },
  { path: "/SMSTemplate", element: <SMSTemplate /> },
  { path: "/Bank", element: <Bank /> },
  { path: "/BankIfsc", element: <BankIfsc /> },
  { path: "/DigitalWallet", element: <DigitalWalletDashBoard /> },
  { path: "/CreateUser", element: <Createuser /> },
  { path: "/EditDetails", element: <EditDetails /> },
  { path: "/Bbpsservices", element: <BBPSServices /> },
  { path: "/TransactionReports", element: <TransactionReports /> },
  { path: "/LiveTransactionReport", element: <LiveTransactionReport /> },
  { path: "/LiveTransactionServices", element: <LiveTransactionServices /> },
  { path: "/PendingTransactions", element: <PendingTransactions /> },
  { path: "/APISaleReport", element: <APISaleReport /> },
  { path: "/APISaleReportservices", element: <APISaleReportservices /> },
  {
    path: "/PendingTransactionservices",
    element: <PendingTransactionservices />,
  },
  { path: "/RefundReports", element: <RefundReports /> },
  { path: "/UpdateTransactions", element: <UpdateTransactions /> },
  {
    path: "/UpdateTransactionsservices",
    element: <UpdateTransactionsservices />,
  },
  { path: "/SMSReport", element: <SMSReport /> },
  { path: "/SMSReportservices", element: <SMSReportservices /> },
  { path: "/Summary", element: <Summary /> },
  { path: "/UserSummary", element: <UserSummary /> },
  { path: "/ReferSummary", element: <ReferSummary /> },
  { path: "/GSTTDS", element: <GSTTDS /> },
  { path: "/UserUsage", element: <UserUsage /> },
  { path: "/APIReconciliation", element: <APIReconciliation /> },
  {
    path: "/APIReconciliationservices",
    element: <APIReconciliationservices />,
  },
  { path: "/OperatorReconciliation", element: <OperatorReconciliation /> },
  {
    path: "/OperatorReconciliationservices",
    element: <OperatorReconciliationservices />,
  },
  {
    path: "/OperatorReconciliationcustom",
    element: <OperatorReconciliationcustom />,
  },
  {
    path: "/Operatorcustomservices",
    element: <Operatorcustomservices />,
  },
  {
    path: "/Extranodebit",
    element: <Extranodebit />,
  },
  {
    path: "/MissingBalance",
    element: <MissingBalance />,
  },
  {
    path: "/MissingBalanceservices",
    element: <MissingBalanceservices />,
  },
  {
    path: "/Transactioncountserwise",
    element: <Transactioncountserwise />,
  },

  {
    path: "/Transactioncountservice",
    element: <Transactioncountservice />,
  },

  {
    path: "/Operatorcommwise",
    element: <Operatorcommwise />,
  },

  {
    path: "/Operatorcommwiseservice",
    element: <Operatorcommwiseservice />,
  },
  {
    path: "/Txnprocesscount",
    element: <Txnprocesscount />,
  },
  {
    path: "/Txnprocesscountservice",
    element: <Txnprocesscountservice />,
  },
  {
    path: "/ReconciliationReport",
    element: <ReconciliationReport />,
  },
  {
    path: "/ReconciliationReportservice",
    element: <ReconciliationReportservice />,
  },
  {
    path: "/DownlineSummary",
    element: <DownlineSummary/>,
  },
  {
    path: "/DownlineSummarytable",
    element: <DownlineSummarytable/>,
  },
  {
    path: "/PaymentStatus",
    element: <PaymentStatus/>,
  },
  {
    path: "/PaymentStatustable",
    element: <PaymentStatustable/>,
  },
  {
    path: "/DuplicateTransactions",
    element: <DuplicateTransactions/>,
  },
  {
    path: "/DuplicateTransactionstable",
    element: <DuplicateTransactionstable/>,
  },
  {
    path: "/RequestLog",
    element: <RequestLog/>,
  },
  {
    path: "/RequestLogtable",
    element: <RequestLogtable/>,
  },
  {
    path: "/ResponseLog",
    element: <ResponseLog/>,
  },
  {
    path: "/ResponseLogtable",
    element: <ResponseLogtable/>,
  },
  {
    path: "/ActivityLog",
    element: <ActivityLog/>,
  },
  {
    path: "/ActivityLogtable",
    element: <ActivityLogtable/>,
  },
  {
    path: "/DuplicateLog",
    element: <DuplicateLog/>,
  },
  {
    path: "/DuplicateLogtable",
    element: <DuplicateLogtable/>,
  },
];

export default RoutesConfig;
