import React, { createContext, useState } from "react";
export const GlobalContext = createContext();
const GlobalContextProvider = ({ children }) => {
  const [globalUser, setGlobalUser] = useState({});
  const [editableUser, setEditableUser] = useState();
  return (
    <GlobalContext.Provider value={{ globalUser, setGlobalUser ,editableUser,setEditableUser}}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
