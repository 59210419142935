import React, { Suspense } from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import { useLocation, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutesConfig from './Routes/Routes'; // Importing RoutesConfig
import GlobalContextProvider from './Components/GlobalContext/GlobalContext';

function App() {
  const location = useLocation();
  const hideNavBar = location.pathname === '/';

  return (
    <div className='appDiv'>
      <GlobalContextProvider>
        {!hideNavBar && <NavBar />}
        
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {RoutesConfig.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Suspense>

        <ToastContainer />
      </GlobalContextProvider>
    </div>
  );
}

export default App;
