import "./NavBar.css";
import { useNavigate } from "react-router-dom";
import logoImage from "../../assets/logoyellow.png";
import React, { useEffect, useState } from "react";

const NavBar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredFieldsData, setFilterFieldsData] = useState([]);

  const navigate = useNavigate();
  const handleLogOut = async () => {
    navigate("/");
  };
  const handleSubOptionClick = (subOption) => {
    setSelectedItem({ type: "sub-option", name: subOption });
    setActiveDropdown(null);
    setActiveSubDropdown(null);

    if (subOption === "Add") {
      navigate("/CreateUser");
      console.log("Add clicked! Navigating to CreateUser.");
    } else if (subOption === "Operators") {
      navigate("/Maintenance");
      console.log("Operators clicked!");
    } else if (subOption === "Operators update") {
      navigate("/OperatorsUpdate");
    } else if (subOption === "Operators param") {
      navigate("/OperatorsParam");
      console.log("Operators param clicked!");
    } else if (subOption === "List/View") {
      navigate("/MerchantDetails");
    } else if (subOption === "Commission/Api settings") {
      navigate("/CommissionCreation");
      console.log("Commission/Api settings clicked!");
    } else if (subOption === "Transaction API") {
      navigate("/Transactionapi");
      console.log("Commission/Api settings clicked!");
    } else if (subOption === "SMS API") {
      navigate("/SMSApi");
      console.log("Commission/Api settings clicked!");
    } else if (subOption === "API Forward") {
      navigate("/APIForwarding");
      console.log("API Forward clicked!");
    } else if (subOption === "API Forward scheme wise") {
      navigate("/APIForwardingscheme");
      console.log("Api forward scheme clicked!");
    } else if (subOption === "API Commission") {
      navigate("/APICommission");
      console.log("Api commission clicked!");
    } else if (subOption === "SMS Templates") {
      navigate("/SMSTemplate");
      console.log("SMS Templates clicked!");
    } else if (subOption === "Bank") {
      navigate("/Bank");
      console.log("Bank clicked!");
    } else if (subOption === "Bank Ifsc data") {
      navigate("/BankIfsc");
      console.log("Bank Ifsc data clicked!");
    } else if (subOption === "view") {
      navigate("/EditDetails");
      console.log("EditDetails clicked!");
    } else if (subOption === "Summary") {
      navigate("/Summary");
      console.log("Summary clicked!");
    } else if (subOption === "User Summary") {
      navigate("/UserSummary");
      console.log("User Summary clicked!");
    } else if (subOption === "Refer Summary") {
      navigate("/ReferSummary");
      console.log("Refer Summary clicked!");
    } else if (subOption === "Refer Summary") {
      navigate("/ReferSummary");
      console.log("Refer Summary clicked!");
    } else if (subOption === "API Reconciliation") {
      navigate("/APIReconciliation");
      console.log("API Reconciliation clicked!");
    } else if (subOption === "Operator Reconciliation") {
      navigate("/OperatorReconciliation");
      console.log("Operator Reconciliation clicked!");
    } else if (subOption === "Operator Reconciliation Custom") {
      navigate("/OperatorReconciliationcustom");
      console.log("Operator Reconciliation Custom clicked!");
    } else if (subOption === "Extra/Nodebit") {
      navigate("/Extranodebit");
      console.log("Extra/Nodebit clicked!");
    } else if (subOption === "Missing Balance") {
      navigate("/MissingBalance");
      console.log("Missing Balance clicked!");
    } else if (subOption === "Transaction Count Servicewise") {
      navigate("/Transactioncountserwise");
      console.log("Transaction Count Servicewise clicked!");
    } else if (subOption === "Operator Comm userwise") {
      navigate("/Operatorcommwise");
      console.log("Operator Comm userwise clicked!");
    } else if (subOption === "Txn.process Count") {
      navigate("/Txnprocesscount");
      console.log("Txn.process Count clicked!");
    } else if (subOption === "Reconciliation Report") {
      navigate("/ReconciliationReport");
      console.log("Reconciliation Report clicked!");
    } else {
      setSelectedItem({ type: "subOption", name: subOption });
    }
    setActiveSubDropdown(null);
  };

  const fieldsData = [
    {
      fieldName: "DashBoard",
      options: [
        {
          optionName: "Welcome",
          navigateTo: "/Dashboard",
        },
      ],
    },
    {
      fieldName: "Master Management",
      options: [
        {
          optionName: "Operators",
          subOptions: ["Operators", "Operators update", "Operators param"],
        },
        {
          optionName: "APIs",
          subOptions: [
            "Transaction API",
            "SMS API",
            "Commission/Api settings",
            "API Forward",
            "API Forward scheme wise",
            "API Commission",
            "SMS Templates",
          ],
        },
        {
          optionName: "Bank",
          navigateTo: "/Bank",
        },
        {
          optionName: "Scheme",
          navigateTo: "/SchemeCreation",
        },
        {
          optionName: "Bank Ifsc data",
          navigateTo: "/BankIfsc",
        },
        {
          optionName: "Bbps upload excel",
          navigateTo: "/BBPSServices",
        },
      ],
    },

    {
      fieldName: "User Management",

      options: [
        {
          optionName: "Merchant",
          subOptions: ["Register", "List/View", "Kyc requests"],
        },
        {
          optionName: "Customer",
          subOptions: ["Register", "List/View", "Kyc requests"],
        },
        {
          optionName: "Users log",
        },
      ],
    },
    {
      fieldName: "Reports",

      options: [
        {
          optionName: "Transaction Reports",
          // navigateTo: "/BbpsReports",
          navigateTo: "/TransactionReports",
        },
        {
          optionName: "Live Transaction Reports",
          navigateTo: "/LiveTransactionReport",
        },
        {
          optionName: "Pendings",
          navigateTo: "/PendingTransactions",
        },
        {
          optionName: "API sale report",
          navigateTo: "/APISaleReport",
        },
        {
          optionName: "Refund Report",
          navigateTo: "/RefundReports",
        },
        {
          optionName: "Update Transaction Reports",
          navigateTo: "/UpdateTransactions",
        },
        {
          optionName: "SMS Reports",
          navigateTo: "/SMSReport",
        },
        {
          optionName: "Summary",
          subOptions: ["Summary", "User Summary", "Refer Summary"],
        },
        {
          optionName: "GST/TDS",
          navigateTo: "/GSTTDS",
        },
        {
          optionName: "User Usage",
          navigateTo: "/UserUsage",
        },
        {
          optionName: "Reconciliation",
          subOptions: [
            "API Reconciliation",
            "Operator Reconciliation",
            "Operator Reconciliation Custom",
            "Extra/Nodebit",
            "Missing Balance",
            "Transaction Count Servicewise",
            "Operator Comm userwise",
            "Txn.process Count",
            "Reconciliation Report",
          ],
        },
        {
          optionName: "Downline Summary",
          navigateTo: "/DownlineSummary",
        },
        {
          optionName: "Payment Status",
          navigateTo: "/PaymentStatus",
        },
        {
          optionName: "Duplicate Transactions ",
          navigateTo: "/DuplicateTransactions",
        },
      ],
    },
    {
      fieldName: "Business Management",

      options: [
        {
          optionName: "Banners",
        },
        {
          optionName: "FAQ",
        },
        {
          optionName: "News Update",
        },
        {
          optionName: "Welcome News Update",
        },
        {
          optionName: "Pages&Media",
          subOptions: ["Privacy Policy", "AboutUs", "Social Media Links", ""],
        },
      ],
    },
    {
      fieldName: "App Management",

      options: [
        {
          optionName: "Banners",
        },
        {
          optionName: "Ad Spaces",
        },
        {
          optionName: "Images",
        },
        {
          optionName: "News Update",
        },
        {
          optionName: "Welcome News Update",
        },
        {
          optionName: "Push Notification",
        },
      ],
    },
    {
      fieldName: "PRO Management",

      options: [
        {
          optionName: "Wallet settings",
          navigateTo: "/DigitalWallet",
        },
        {
          optionName: "Settlement settings",
        },
      ],
    },
    {
      fieldName: "Settings",

      options: [
        {
          optionName: "How Balance Alert",
        },
        {
          optionName: "Services Limit Restrictions",
        },
        {
          optionName: "Roles",
          subOptions: ["Add", "view"],
        },
      ],
    },
    {
      fieldName: "Support Center",

      options: [
        {
          optionName: "Browse Tickets",
        },
        {
          optionName: "Raise Complaints",
        },
        {
          optionName: "Payment Requests",
        },
        {
          optionName: "Payment History",
        },
        {
          optionName: "Request Log",
          navigateTo: "/RequestLog",
        },
        {
          optionName: "Response Log",
          navigateTo: "/ResponseLog",
        },
        {
          optionName: "Activity Log",
          navigateTo: "/ActivityLog",
        },
        {
          optionName: "App Permission Log",
        },
        {
          optionName: "Duplicate Deposite Log",
          navigateTo: "/DuplicateLog",
        },
        {
          optionName: "Kyc Request Log",
        },
        {
          optionName: "Website Contacts Logs",
        },
        {
          optionName: "BBPS Complaints Logs",
        },
      ],
    },
    {
      fieldName: "Search",

      options: [
        {
          optionName: "Option 2.1",
        },
        {
          optionName: "Option 2.2",
        },
      ],
    },
  ];
  const handleDropdownHover = (field) => {
    setActiveDropdown(field);
    setActiveSubDropdown(null);
  };
  const handleSubDropdownHover = (option) => {
    setActiveSubDropdown(option);
  };
  const handleOptionClick = (option) => {
    if (option.navigateTo) {
      navigate(option.navigateTo);
    } else if (option.subOptions) {
      setActiveSubDropdown(option.optionName);
    } else {
      setSelectedItem({ type: "option", name: option.optionName });
      setActiveSubDropdown(null);
    }
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user")) || {};
    const role = user.role || null;
    const services = user.services || [];
    const filteredField =
      role === "admin"
        ? fieldsData
        : fieldsData
            .map((field) => ({
              ...field,
              options: field.options.filter(
                (option) =>
                  services.includes(option.optionName) ||
                  option.subOptions?.some((subOption) =>
                    services.includes(subOption)
                  )
              ),
            }))
            .filter((field) => field.options.length > 0);

    setFilterFieldsData(filteredField);
  }, []);
  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    let servicesData = [];
    let role = null;

    if (userData) {
      const user = JSON.parse(userData);
      servicesData = user.services || [];
      role = user.role || null;
    }

    console.log("servicesData of user===>", JSON.stringify(servicesData));
    console.log("fieldsData of application===>", JSON.stringify(fieldsData));

    const filterApplicationData = (fieldsData, servicesData) => {
      return fieldsData
        ?.map((field) => {
          const filteredOptions = field.options
            .filter((option) =>
              servicesData.some(
                (service) => service.mainServices === option.optionName
              )
            )
            .map((option) => {
              const userService = servicesData.find(
                (service) => service.mainServices === option.optionName
              );
              if (userService) {
                const filteredSubOptions = option.subOptions
                  ? option.subOptions.filter((subOption) =>
                      userService.subServices.includes(subOption)
                    )
                  : [];
                return {
                  ...option,
                  subOptions:
                    filteredSubOptions.length > 0
                      ? filteredSubOptions
                      : undefined,
                };
              }
              return option;
            });

          return {
            ...field,
            options: filteredOptions,
          };
        })
        .filter((field) => field.options.length > 0);
    };

    const filteredData =
      role === "admin"
        ? fieldsData
        : filterApplicationData(fieldsData, servicesData);
    setFilterFieldsData(filteredData);
    console.log("Filtered Fields Data: ", filteredData);
  }, []);

  return (
    <div
      style={{
       
        display: "flex",
        justifyContent: "center",
        margin: "32px",
      }}
    >
      <div className="navbar-container">
        <div className="top-bar">
          <div className="logo-container">
            <img src={logoImage} alt="Icon" className="logoIcon" />
            <span className="logo-text">NTAR</span>
          </div>
          <div className="logout-button-container">
            <button onClick={handleLogOut} className="logout-button">
              Logout
            </button>
          </div>
        </div>

        <nav className="navbar">
          <ul className="navbar-menu">
            {filteredFieldsData.map((field, fieldIndex) => (
              <li
                key={fieldIndex}
                className="navbar-item"
                onMouseEnter={() => handleDropdownHover(field.fieldName)}
                onMouseLeave={() => {
                  setActiveDropdown(null);
                  setActiveSubDropdown(null);
                }}
              >
                <button className="dropdown-button">{field.fieldName}</button>
                {activeDropdown === field.fieldName && (
                  <ul className="dropdown-menu">
                    {field.options.map((option, optionIndex) => (
                      <li
                        key={optionIndex}
                        onMouseEnter={() =>
                          handleSubDropdownHover(option.optionName)
                        }
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.optionName}
                        </button>
                        {activeSubDropdown === option.optionName &&
                          option.subOptions?.length > 0 && (
                            <ul className="sub-dropdown-menu">
                              {option.subOptions.map(
                                (subOption, subOptionIndex) => (
                                  <li
                                    key={subOptionIndex}
                                    className="sub-dropdown-item"
                                    onClick={() =>
                                      handleSubOptionClick(subOption)
                                    }
                                  >
                                    {subOption}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default NavBar;
